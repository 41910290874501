import * as singleSpa from "single-spa";

const applications = [
  {
    name: "mfe-login",
    app: () => System.import("mfe-login"),
    activeWhen: ["/login", (location) => location.pathname.length == 1],
  },
  {
    name: "mfe-login-404",
    app: () => System.import("mfe-login"),
    activeWhen: ["404", (location) => location.pathname === "/404"],
  },
  {
    name: "mfe-login-change-password",
    app: () => System.import("mfe-login"),
    activeWhen: ["change-password", (location) => location.pathname === "/change-password"],
  },
  {
    name: "portal-mfe-etrm",
    app: () => System.import("portal-mfe-etrm"),
    activeWhen: [
      "/etrm",
      (location) => location.pathname.startsWith("/etrm"),
    ],
  },
];

applications.forEach((apps: any) => {
  singleSpa.registerApplication(apps);
});

singleSpa.getAppNames();
singleSpa.checkActivityFunctions(location);

singleSpa.triggerAppChange();

singleSpa.start({
  urlRerouteOnly: true,
});

singleSpa.addErrorHandler((_err) => {});
